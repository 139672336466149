import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Profile() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [userData, setUserData] = useState({ username: '', profilePictureUrl: '', posts: [] });
    const userId = localStorage.getItem('userId');
    // console.log(userData);
    useEffect(() => {
        // Fonction pour récupérer les informations de l'utilisateur
        const fetchUserData = async () => {
            try {
                // Remplacer par l'URL de votre API
                const response = await axios.get(`https://api.mangascollection.com/api/user/${userId}`);
                // const response = await axios.get(`http://localhost:3002/api/user/${userId}`);
                setUserData({
                    username: response.data.username,
                    // profilePictureUrl: `https://mangascollection.com/Medias/ProfilesPictures/${response.data.username}_profilepic.png`,
                    profilePictureUrl: `https://api.mangascollection.com/Medias/ProfilesPictures/${response.data.profilePicturePath}`,
                    // profilePictureUrl: `http://localhost:3002/Medias/ProfilesPictures/${response.data.profilePicturePath}`,
                    posts: response.data.posts[0] ||[]
                });
            } catch (error) {
                console.error('Erreur lors de la récupération des informations de l\'utilisateur', error);
            }
        };

        fetchUserData();
    }, [userId]);

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedFile) {
            return;
        }

        const formData = new FormData();
        formData.append('profilePicture', selectedFile);
        const user_id_local = localStorage.getItem('userId');
        formData.append('user_id', user_id_local); // Ajoutez l'user_id à formData

        try {
            await axios.post('https://api.mangascollection.com/api/upload-profile-picture', formData, {
                // await axios.post('http://localhost:3002/api/upload-profile-picture', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (error) {
            console.error('Erreur lors du téléversement de l\'image', error);
        }
    };


    return (
        <div className='ProfilePage'>
            <h1>Profil de {userData.username}</h1>
            <img src={userData.profilePictureUrl} alt="Profil" />

            <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleFileSelect} />
                <button type="submit">Téléverser Image</button>
            </form>

            <h2>Posts de {userData.username}</h2>
            <div className='posts'>
                {userData.posts.map(post => (
                    <div className='post' key={post.post_id}>
                        <img src={userData.profilePictureUrl} alt="Profil" />

                        <p>{userData.username}</p>
                        <p>{post.content}</p>
                        <p>{post.created_at}</p>
                    </div>
                ))}

            </div>
        </div>

    );
}

export default Profile;