import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Assurez-vous que le chemin est correct
import axios from 'axios'; // Assurez-vous d'avoir installé axios

function Feed() {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]); // State pour stocker les posts
    const [newPostContent, setNewPostContent] = useState(''); // State pour le nouveau post

    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        try {
            const response = await axios.get('https://api.mangascollection.com/api/posts');
            // const response = await axios.get('http://localhost:3002/api/posts');
            setPosts(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des posts', error);
        }
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };
    const GotoProfile = () => {
        navigate('/profile');
    };


    const handleNewPostChange = (e) => {
        setNewPostContent(e.target.value);
    };

    const handleSubmitPost = async (e) => {
        e.preventDefault();
        if (!newPostContent) return;
        const user_id_local = localStorage.getItem('userId');

        try {
            await axios.post('https://api.mangascollection.com/api/posts', {user_id: user_id_local, content: newPostContent });
            // await axios.post('http://localhost:3002/api/posts', {user_id: user_id_local, content: newPostContent });
            setNewPostContent('');
            fetchPosts();
        } catch (error) {
            console.error('Erreur lors de la publication du post', error);
        }
    };

    return (
        <div className='Feed'>
            <div className='feed-nav'>

                <h2>Feed</h2>
                <div className='feed-nav-buttons'>
                    <button onClick={GotoProfile}>Profile</button>
                    <button onClick={handleLogout}>Déconnexion</button>
                </div>
            </div>

            <div className='feed-container-posts'>
                <form className='container-posting' onSubmit={handleSubmitPost}>
                    <textarea value={newPostContent} onChange={handleNewPostChange} placeholder="Écrivez quelque chose..." />
                    <button type="submit">Poster</button>
                </form>
                <div className='container-posts'>
                    {posts.map(post => (
                        <div key={post.post_id}>
                            <img src={post.profilePictureUrl} alt={`${post.username}'s profile`} />
                            <h3>{post.username}</h3>
                            <p>{post.content}</p>
                            <span>{post.created_at}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Feed;