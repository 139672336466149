import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Assurez-vous que le chemin est correct
import { Link } from 'react-router-dom';
import "./css/Home.scss"

function Signin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { setIsAuthenticated } = useAuth();
    const signupSuccessMessage = localStorage.getItem('signupSuccess');
    // localStorage.removeItem('signupSuccess');


    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('https://api.mangascollection.com/api/signin', {
        // fetch('http://localhost:3002/api/signin', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la connexion');
                }
                return response.json();
            })
            .then(data => {
                if (data.success) {
                    localStorage.setItem('isAuthenticated', 'true'); // Stocker l'état d'authentification
                    console.log(data);
                    localStorage.setItem('userId', data.user_id); // Stocker l'état d'authentification
                    setIsAuthenticated(true);
                    navigate('/feed');
                } else {
                    console.error('Erreur de connexion:', data.message);
                }
            })
            .catch(error => {
                console.error('Erreur:', error);
            });
    };

    return (
        <div className='signinpage'>
            <h2>MANGACADEMY</h2>
            <h6>Social Networking Site</h6>
            {signupSuccessMessage && <p className="success-message">{signupSuccessMessage}</p>}

            <form onSubmit={handleSubmit} className='signinform'>
                <label className="emailinput">
                    <input placeholder='Email' type="email" value={email} onChange={e => setEmail(e.target.value)} />
                </label>
                <label className="passwordinput">
                    <input placeholder='Mot de Passe' type="password" value={password} onChange={e => setPassword(e.target.value)} />
                </label>
                <div className='accueilbuttons'>
                    <button className='signinbutton' type="submit">Se connecter</button>
                </div>
                <div className='signupbtncontainer'>
                    Pas encore de compte?
                    <Link to="/signup">
                        S'inscrire
                    </Link>
                </div>
            </form>

        </div>
    );
}

export default Signin;