import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext'; // Importez AuthContext
import Signup from './Components/Signup'; // Importez votre composant Signup
import Signin from './Components/Signin'; // Importez votre composant Signin
import Feed from './Components/Feed';     // Importez votre composant Feed
import Profile from './Components/Profile';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={
            <PublicRoute>
              <Signin />
            </PublicRoute>
          } />
          <Route path="/signup" element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          } />
          {/* <Route path="/signin" element={
            <PublicRoute>
              <Signin />
            </PublicRoute>
          } /> */}
          <Route path="/feed" element={
            <PrivateRoute>
              <Feed />
            </PrivateRoute>
          } />
          <Route path="/profile" element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          } />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

function PrivateRoute({ children }) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // Si l'utilisateur n'est pas authentifié, redirigez-le vers la page d'accueil
    return <Navigate to="/" replace />;
  }

  return children;
}

export default App;

function PublicRoute({ children }) {
  const { isAuthenticated } = useAuth();
  console.log(isAuthenticated);
  if (isAuthenticated) {
    // Si l'utilisateur est authentifié, redirigez-le vers la page feed
    return <Navigate to="/feed" replace />;
  }

  return children;
}
