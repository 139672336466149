import React, { useState } from 'react';
// import { useAuth } from '../AuthContext'; // Assurez-vous que le chemin est correct
// import { useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Signup() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // Ajout d'un état pour le message d'erreur
    const navigate = useNavigate();

    // const { setIsAuthenticated } = useAuth();

    // Après une connexion réussie
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage('');

        fetch('https://api.mangascollection.com/api/signup', {
            // fetch('http://localhost:3002/api/signup', {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, email, password })
        })
            .then(response => {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    return response.json();
                } else {
                    return response.text();
                }
            })
            .then(data => {
                console.log(data);
                if (data.success) {
                    localStorage.setItem('signupSuccess', 'Votre compte a été créé avec succès');
                    navigate('/');
                } else {
                    setErrorMessage(data.message);
                    console.log("Message d'erreur défini : ", data.message); // Pour le débogage
                }

            })
            .catch(error => {
                console.error('Erreur:', error);
                setErrorMessage('Une erreur inattendue s\'est produite');
            });
    };





    return (
        <div className='signuppage'>
            <h2>Inscription</h2>
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <form onSubmit={handleSubmit} className='signupform'>
                <label className="usernameinput">
                    <input placeholder="Nom d'utilisateur" type="text" value={username} onChange={e => setUsername(e.target.value)} />
                </label>
                <label className="emailinput">
                    <input placeholder="Email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
                </label>
                <label className="passwordinput">
                    <input placeholder="Mot de Passe" type="password" value={password} onChange={e => setPassword(e.target.value)} />
                </label>
                <div className='accueilbuttons'>
                    <button className='signupbutton' type="submit">S'inscrire</button>
                </div>
            </form>


        </div>
    );
}

export default Signup;